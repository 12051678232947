

























































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Filter, FilterCondition, FilterOperator, FilterRule, FieldTypeDefinition } from '@/models'
import Fields from '@/components/form/Fields.vue'
import Field from '@/components/fields/Field.vue'
import Loading from '@/components/Loading.vue'
import _cloneDeep from 'lodash/cloneDeep'
import gql from 'graphql-tag'
import { FilterFragment, FilterUpdateBasicFragment, FilterUpdateConditionsFragment } from './fragments'
import draggable from 'vuedraggable'
import _isEqual from 'lodash/isEqual'
import cleanData from '@/utils/gql/cleanData'
import { confirmDelete } from '@/components/dialogs'

@Component({
  apollo: {
    savedFilter: {
      query: gql`query getFilter ($filterId : ID) {
        savedFilter: filter (filterId: $filterId) {
          ...Filter
        }
      }
      ${FilterFragment}`,
      variables () {
        return {
          filterId: this.componentId
        }
      }
    },
    operators: gql`query getOperators {
      operators {
        _id
        name
        inputType
        fieldType {
          _id
          name
          optionsParams
        }
      }
    }`,
    fieldTypes: gql`query {
      fieldTypes {
        _id
        name
        optionsParams
      }
    }`
  },
  components: {
    Fields,
    Field,
    Loading,
    draggable
  }
})
export default class FilterEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId !: String
  @Prop({ type: String, required: true }) componentId !: String

  savedFilter : Filter | null = null
  filter : Partial<Filter> = {}
  operators : FilterOperator[] = []
  editableSheetOpen : Record<number, boolean> = {}

  fieldTypes : FieldTypeDefinition[] = []
  saving = false
  valueTypes = [
    { label: 'Valor Fijo', icon: 'build', value: 'fixed', color: 'blue-grey' },
    { label: 'Parámetro', icon: 'settings_ethernet', value: 'parameter', color: 'orange' },
    { label: 'Editable', icon: 'keyboard', value: 'editable', color: 'brown' }
  ]

  get fields () {
    return [
      { name: '_id', label: 'ID', type: 'string', fieldType: { _id: 'string', allowedOperatorsIds: 'idEquals' }, optional: true },
      ...(this.filter?.collection?.fields || [])
    ]
  }

  get dirty () {
    return !_isEqual(this.filter, this.savedFilter)
  }

  @Watch('savedFilter')
  updateSavedFilter (savedFilter : Filter) {
    this.$set(this, 'filter', _cloneDeep(savedFilter))
  }

  @Watch('filter.name')
  updateName (newName : string) {
    this.$emit('name', newName)
  }

  getFieldTypeForRule (rule : FilterRule) {
    if (!rule || !rule.operatorId) return {}
    const operator = this.getOperatorForRule(rule) as FilterOperator
    if (!operator || !operator.inputType) return {}
    return this.fieldTypes.find(t => t._id === operator.inputType)
  }

  addConditionGroup () {
    if (!this.filter?.conditions) return
    this.filter.conditions.push({
      rules: []
    })
  }

  removeConditionGroup (index : number) {
    if (!this.filter?.conditions) return
    this.filter.conditions.splice(index, 1)
  }

  addRuleToCondition (condition : FilterCondition) {
    condition.rules.push({
      // @ts-ignore
      type: 'fixed',
      fixed: {
        value: null
      }
    })
  }

  removeConditionRule (condition : FilterCondition, ruleIndex : number) {
    condition.rules.splice(ruleIndex, 1)
  }

  getFieldForRule (rule ?: FilterRule) {
    if (!rule || !rule.fieldName) return {}
    return this.fields.find(f => f.name === rule?.fieldName) || {}
  }

  getOperatorsForRule (rule ?: FilterRule) {
    if (!rule || !rule.fieldName) return []
    const field = this.fields.find(f => f.name === rule?.fieldName)
    if (!field) return []
    return this.operators.filter(o => field.fieldType?.allowedOperatorsIds.includes(o._id))
  }

  getOperatorForRule (rule ?: FilterRule) {
    if (!rule || !rule.operatorId) return {}
    return this.operators.find(o => o._id === rule.operatorId) || {}
  }

  handleRuleTypeChange (rule : FilterRule, valueType : 'fixed' | 'parameter' | 'editable') {
    if (valueType !== 'fixed') {
      this.$set(rule, 'fixed', null)
    } else {
      this.$set(rule, 'fixed', { value: null })
    }
    if (valueType !== 'parameter' && valueType !== 'editable') {
      this.$set(rule, 'parameterName', null)
    }
    if (valueType !== 'editable') {
      this.$set(rule, 'editableLabel', null)
      this.$set(rule, 'sizeLarge', null)
      this.$set(rule, 'sizeSmall', null)
    } else {
      this.$set(rule, 'editableLabel', (this.getFieldForRule(rule) as any).label || '')
      this.$set(rule, 'sizeLarge', '12')
      this.$set(rule, 'sizeSmall', '12')
    }
  }

  setRuleEditableSmallSize (rule : FilterRule, size : number) {
    rule.sizeSmall = size.toString()
  }

  setRuleEditableLargeSize (rule : FilterRule, size : number) {
    rule.sizeMedium = rule.sizeLarge = size.toString()
  }

  async save () {
    if (!this.filter || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`mutation ($filterId : ID, $filter: UpdateFilterBasicInput, $conditions: [UpdateFilterConditionInput]) {
          updateFilter (filterId: $filterId, filter: $filter) {
            ...FilterCreate
          }

          updateFilterConditions (filterId: $filterId, conditions: $conditions) {
            ...Filter
          }
        }
        ${FilterFragment}
        `,
        // Parameters
        variables: {
          filterId: this.filter._id,
          filter: cleanData(this.filter, FilterUpdateBasicFragment),
          conditions: cleanData(this.filter, FilterUpdateConditionsFragment).conditions
        }
      })

      this.savedFilter = result.data.updateFilterConditions
      this.$emit('save', result.data.updateFilter)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem () {
    if (!await confirmDelete('¿Seguro que quieres eliminar por completo este filtro del ambiente?')) return
    if (!this.filter || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`mutation ($filterId : ID) {
          removeFilter (filterId: $filterId)
        }`,
        // Parameters
        variables: {
          filterId: this.filter._id
        }
      })
      this.$emit('delete', result.data.removeFilter)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  dismiss () {
    this.$emit('dismiss')
  }
}
